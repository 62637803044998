.dayTime-block {
    background-color: gray;
}

.reservationsBlockWrapper {
    padding: 0;
}

.reservationBlock {
    border: 1px solid black;
    display: inline-block;
    padding: 6px;
    margin: 2px;
    font-size: 1.25em;
}

.reservationBlock-booked {
    color: white;
    background-color: red;
    cursor: not-allowed;
    font-weight: bold;
}

.reservationBlock-free {
    color: black;
    cursor: pointer;
}

.reservationBlock-active {
    color: white;
    background-color: green;
    cursor: pointer;
    font-weight: bold;
}

.reservationsBlockDisabledWrapper {
    position: relative;
}

.reservationsBlockDisabledOverlay {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    background-color: rgba(192, 192, 192, 0.9);
}