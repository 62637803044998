.map-wrapper {
    overflow:hidden;
    resize:none;
    max-width:100%;
    /* width:150px; */
    height:150px;
}

.map-inner-wrapper {
    height:100%;
    width:100%;
    max-width:100%;
}

.map-iframe-wrapper {
    height:100%;
    width:100%;
    border:0;
}

#google-maps-canvas img{
    max-width:none!important;
    background:none!important;
    font-size: inherit;
    font-weight:inherit;
}