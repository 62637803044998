body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.dayTime-block {
    background-color: gray;
}

.reservationsBlockWrapper {
    padding: 0;
}

.reservationType {
    backgorund-color: gray;
    border: 1px solid black;
    display: inline-block;
    padding: 6px;
    margin: 2px;
}

.reservationType-booked {
    color: red;
}

.reservationType-free {
    color: black;
    cursor: pointer;
}
.dayTime-block {
    background-color: gray;
}

.reservationsBlockWrapper {
    padding: 0;
}

.reservationBlock {
    border: 1px solid black;
    display: inline-block;
    padding: 6px;
    margin: 2px;
    font-size: 1.25em;
}

.reservationBlock-booked {
    color: white;
    background-color: red;
    cursor: not-allowed;
    font-weight: bold;
}

.reservationBlock-free {
    color: black;
    cursor: pointer;
}

.reservationBlock-active {
    color: white;
    background-color: green;
    cursor: pointer;
    font-weight: bold;
}

.reservationsBlockDisabledWrapper {
    position: relative;
}

.reservationsBlockDisabledOverlay {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    background-color: rgba(192, 192, 192, 0.9);
}
.calendar-wrapper {
    width: 100%;
}
header img {
    width: 100%;
}
.map-wrapper {
    overflow:hidden;
    resize:none;
    max-width:100%;
    /* width:150px; */
    height:150px;
}

.map-inner-wrapper {
    height:100%;
    width:100%;
    max-width:100%;
}

.map-iframe-wrapper {
    height:100%;
    width:100%;
    border:0;
}

#google-maps-canvas img{
    max-width:none!important;
    background:none!important;
    font-size: inherit;
    font-weight:inherit;
}
