.dayTime-block {
    background-color: gray;
}

.reservationsBlockWrapper {
    padding: 0;
}

.reservationType {
    backgorund-color: gray;
    border: 1px solid black;
    display: inline-block;
    padding: 6px;
    margin: 2px;
}

.reservationType-booked {
    color: red;
}

.reservationType-free {
    color: black;
    cursor: pointer;
}